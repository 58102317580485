@import "../styles/variables.scss";
@import "../styles/typography.scss";

@keyframes signing {
  0% { background-color: $palette_controlAction__hover; }
  50% { background-color: $palette_secondary__light; }
  100% { background-color: $palette_controlAction__hover; }
}

.auth {
  width: 100vw;
  height: 100vh;
  background-image: url(../static/imgs/AuthBackground.svg);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  .card {
    max-width: 320px !important;
    .cardContent {
      display: flex;
      flex-direction: column;
      padding-top: 12px;
      padding-bottom: 12px;
    }
    .authRemember {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .MuiTypography-body2-38 {
      font-size: 20px;
      color: #000;
      font-weight: 500;
      letter-spacing: 1px;
    }
    .MuiFormLabel-root-80  {
      font-size: 12px;
    }
    .MuiInputLabel-outlined-80.MuiInputLabel-shrink-77 {
      transform: translate(14px, -6px) scale(0.9);
      color: $palette_primary__contrastText;
    }
    .MuiInputBase-input-111{
      font-size: 14px;
    }
    .MuiFormLabel-root-81 {
      font-size: 14px;
    }
    .MuiInputBase-root-100 {
      font-size: 16px;
    }
    .switch {
      margin-left: -12px;
    }
    .switchText{
      color: $palette_secondary__main;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 1px;
    }
    .cardAction{
      padding-top: 0;
      padding-bottom: 0;
    }
    .authButton {
      color: #fff;
      width: 272px;
      max-width: none;
      margin: 0 12px 24px 12px;
      text-transform: none;
      font-size: 14px;
      background-color: $palette_controlAction__active;
      &:hover {
        background-color: $palette_controlAction__hover;
      }
      &.not_valid {
        background-color: $palette_controlAction__disable;
      }
      &.error {
        background-color: $palette_secondaryAccent__dark;
      }
      &.requesting {
        animation-name: signing;
        animation-duration: 1s;
        animation-iteration-count: infinite;
      }
    }
  }

  .pos {
    margin-bottom: 12;
  }
}
