.info__wrapper {
	user-select: none;
	opacity: 0.7;
	cursor: pointer;
	width: 40%;
	position: relative;
	transition: 0.2s;
	&:hover {
		opacity: 1;
	}
	div.text {
		margin-bottom: 5px;
		display: flex;
		justify-content: space-between;
	}
	p {
		font-size: 12px;
		&.errors { color: #ff000099; }
	}
}