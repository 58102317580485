.file-preview {
  width: 100%;
  margin-bottom: 5px;
  display: flex;
  padding: 20px 0;
  img.icon {
    width: 48px;
    height: 48px;
    border-radius: 100px;
    border: 0;
    outline: 0;
    background: rgb(100, 216, 221);
    background-image: url(../../static/imgs/files.png);
    background-position: center;
    background-repeat: no-repeat;
    // &.pdf { background-image: url(../static/imgs/files/pdf.png); }
    // &.xls { background-image: url(../static/imgs/files/xls.png); }
    // &.docx { background-image: url(../static/imgs/files/docx.png); }
    // &.media { background-image: url(../static/imgs/files/media.png); }
    // &.other { background-image: url(../static/imgs/files/other.png); }
    &.image {
      border-radius: 8px;
    }
  }
  div.info {
    display: flex;
    border-bottom: 2px dotted rgba(0,0,0,0.1);
    width: calc(100% - 108px);
    justify-content: space-between;
    padding: 0px 30px 20px 0;
    margin-left: 30px;
    div.meta {
      width: 200px;
      p.title { color: black; font-weight: 900; font-size: 17px; cursor: pointer; }
      p.meta { color: black; font-weight: 900; font-size: 13px; }
    }
    div.author {
      width: 300px;
      cursor: pointer;
      p.name { color: black; font-weight: 900; }
      p.description {}
    }
    div.shares {
      width: 300px;
    }
  }
}
