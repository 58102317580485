.searchInput {
  width: 100%;
  z-index: 999;
  position: relative;
  .input {
    width: 100%;
  }
  .suggestions {
    display: none;
    padding: 0;
    width: 100%;
    height: auto;
    max-height: 200px;
    overflow-y: auto;
    background-color: #f2f2f2;
    z-index: 999;
    position: absolute;
    &.visible {
      display: block;
      min-height: 50px;
      .loader {
        * {
          margin: 0 !important;
        }
      }
      .suggestion {
        cursor: pointer;
        &:hover {
          background-color: rgba(0,0,0,0.05);
        }
        .small {
          font-size: 12px;
          margin: 0;
        }
      }
    }
    * {
      cursor: pointer;
      margin: 10px 0 0;
    }
  }
}

.search{
  &__wrapper {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
  }
  &__icon {
    z-index: 999;
    height: 100%;
    position: absolute;
    margin-left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #979797;
    //opacity: 0.4;
    &_clear {
      right: 0;
      margin-right: 7px;
      margin-left: 0;
      &:hover {
        cursor: pointer;
        opacity: 0.4;
      }
    }
  }
  &__content {
    background: rgba(#263238, 0.08);
    box-shadow: 0 4px 8px rgba(84, 110, 122, 0.24);
    border: none;
    border-radius: 5px;
    width: 100%;
    height: 48px;
    color: #979797;
    padding-left: 50px;
    &_clear {
      padding-right: 40px;
    }
  }
}
