$padding: 15px;

.modal_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 35px;
  .modal_sourceEditor {
    grid-area: m;
    background-color: white;
    display: flex;
    border-radius: 32px;
    padding: 0 25px;
    flex-direction: column;
    width: 90vw;
    height: auto;
    max-width: 704px;
    label {
      // transform: translate(14px, 20px) scale(1);
    }
    input {
      padding: 12px 14px;
    }
    .title {
      margin-top: 10px;
      display: flex;
    }
    .inputsRow {
      display: flex;
      .photoInput__wrapper {
        background-color: #EEF2F3;
        margin: 15px 0 20px 20px;
        p {
          color: #D72638;
          margin-right: -5px;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border: solid 1px rgba(0, 0, 0, 0.23);
        }
        label {
          display: flex;
          width: 120px;
          height: 120px;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
        .photoInputIcon {
          background-image: url('../../static/imgs/add_a_photo.png');
        }
        input {display: none;}
      }
    }
    .sourceRow {
      display: flex;
      button{
        text-transform: none;
        margin-left: 20px;
      }
    }
    .buttonRow {
      display: flex;
      margin: 20px 0;
      button{
        text-transform: none;
      }
      button:first-child{
        margin-left: auto;
        max-width: none;
      }
      button:last-child{
        margin-left: 10px;
        max-width: none;
      }
    }
  }
}
