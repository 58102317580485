$gap: 24px;
$lg: 1600px;
$md: 1024px;
$sm: 868px;
$xs: 400px;



.projectDashboard {
  display: grid;
  gap: $gap;
  grid-template: repeat(3, 1fr) / repeat(3, 1fr);
  margin-top: 24px;
}
