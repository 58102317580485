.textField {
  width: 100%;
  margin: 0;
  padding: 0;
  flex-basis: 200;
  flex-grow: 1;
  max-height: 60px;
  max-width: 280px;
}
.labelButton{
  span{
    display: flex;
    text-align: left;
    justify-content: flex-start;
  }
}