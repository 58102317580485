@import "../../styles/variables.scss";
@import "../../styles/typography.scss";

@keyframes requesting {
  0% { opacity: 0.3; } 
  50% { opacity: 1; }
  100% { opacity: 0.3; }
}


.navi {
  border-bottom: solid 0.1px #cbcecf;
  padding: 15px 30px;
  margin: 10px 0 0;
  margin-top: 0;
  margin-right: 0;
  display: flex;
  .backButton {
    width: 34px;
    height: 34px;
    background-position: center center;
    background-image: url(../../static/imgs/keyboard_backspace_24.png);
    background-color: rgba(#d5efff, 0.25);
    border-radius: 50%;
  }
  .separator {
    width: 20px;
    height: 34px;
    background-image: url(../../static/imgs/keyboard_arrow_right_.png);
    background-position: center center;
  }
  .moreVert {
    margin-left: 10px;
    width: 34px;
    height: 34px;
    background-image: url(../../static/imgs/more_vert_24px.png);
    background-position: center center;
    background-color: rgba(#d5efff, 0.25);
    border-radius: 50%;
  }

  a.item {
    display: flex;
    align-items: center;
    @include h6;
    text-decoration: none;
    color: $palette_text__secondary;
    font-size: 20px;
    padding-left: 5px;
    padding-right: 5px;

    &.requesting {
      animation-name: requesting;
      animation-duration: 1.5s;
      animation-iteration-count: infinite;
    }

    &.last { color: black; }
  }
}
