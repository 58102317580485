.personInfo {
  display: flex;
  margin-top: 14px;
  padding-bottom: 24px;
  border-bottom: solid 0.1px #cbcecf;
  .fullNameInput__row {
    display: flex;
  }
  .avatar {
    min-width: 128px;
    height: 128px;
    background: gray;
    border-radius: 50%;
    margin: 0 24px;
    &:hover {
      cursor: pointer;
    }
    input {
      display: none;
    }
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
    label {
      display: flex;
      width: 128px;
      height: 128px;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
  .information {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .information, .contact {
    .fullName, .sex, .profession, .address, .email, .phone {
      display: flex;
      align-items: center;

    }
  }

  .contact {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-around;
    margin: 0 24px 0 auto;
    .map-marker {
      background-image: url(../static/imgs/map-marker.png);
    }
    .emailIcon {
      background-image: url(../static/imgs/email.png);
    }
    .call {
      background-image: url(../static/imgs/call.png);
    }
  }
}
.table__wrapper {
  margin-top: 14px;
  border-bottom: solid 0.1px #cbcecf;
  .icon {
    width: 30px;
    height: 30px;
    background-position: center;
    background-repeat: no-repeat;
  }
  .create {
    background-image: url(../static/imgs/create.png);
    background-color: rgba(213, 239, 255, 0.25);
    border-radius: 50%;
    margin-left: 9px;
    &:hover {
      cursor: pointer;
    }
  }
  .map-marker {
    background-image: url(../static/imgs/map-marker.png);
  }
  .person {
    background-image: url(../static/imgs/person.png);
  }
  .tie {
    background-image: url(../static/imgs/tie.png);
  }
  .like {
    background-image: url(../static/imgs/favorite_border.png);
    &:hover {
      background-image: url(../static/imgs/favorite.png);
      cursor: pointer;
    }
  }
  .comment {
    background-image: url(../static/imgs/comment.png);
    &:hover {
      cursor: pointer;
    }
  }
  .visibility {
    background-image: url(../static/imgs/visibility.png);
  }
  .horizontalCards__wrapper {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(auto-fill, 375px);
    margin-bottom: 30px;
  }
  .verticalCards__wrapper {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(auto-fill, 275px);
    margin-bottom: 30px;
    

    .course {
      width: 275px;
      align-items: center;
      height: auto;
      box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2),
      0px 1px 1px 0px rgba(0,0,0,0.14),
      0px 2px 1px -1px rgba(0,0,0,0.12);
      border-radius: 4px;
      overflow: hidden;

      .textBlock {
        padding: 0 15px 15px;
        .textRow {
          display: flex;
        }
      }
      .buttonRow {
        display: flex;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        button {
          width: 100%;
          min-width: 100%;
          text-transform: none;
        }
      }
      .topRow {
        display: flex;
        flex-direction: row;
      }
      .rewardPoints {
        display: flex;
        padding-right: 10px;
        padding-left: 10px;
        position: relative;
        width: 45%;
        height: auto;
        background-color: rgba(255, 255, 255, 0.4);
        top: -180px;
        left: 44%;
        font-size: 16px;
        white-space: nowrap;
        .symbol {
          padding-right: 10px;
          font-weight: 1000;
        }
      }
      img {
        width: 100%;
        height: 200px;
        object-fit: cover;
      }
    }
  }
  .horizontalCard {
    display: flex;
    width: 375px;
    min-height: 128px;
    .avatar {
      width: 98px;
      height: 98px;
      background: gray;
      border-radius: 50%;

    }
    .textBlock {
      margin-left: 15px;
    }
  }
  .verticalCard {
    width: 275px;
    min-height: 380px;
    .previewImage {
      width: 100%;
      height: 200px;
      background: #d7efff;
    }
    .statistic__row {
      display: flex;
      justify-content: space-around;
      margin: 15px;
      .statistic__element {
        display: flex;
        align-items: center;
      }
    }
    .textBlock {
      margin-left: 15px;
      .theme {
        color: #FFF;
        background-color: #00796B;
        padding: 0 10px;
        border-radius: 3px;
        text-transform: uppercase;
        width: max-content;
        position: relative;
        bottom: 35px;
      }
      .createData__row {
        display: flex;
        white-space: nowrap;
        .avatar {
          width: 98px;
          height: 98px;
          background: gray;
          border-radius: 50%;
        }
      }
      .statistic__row {
        display: flex;
      }
    }
    button {
      max-width: 100%;
      width: 100%;
    }
  }

}
