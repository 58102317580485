@import "../../styles/variables.scss";

.header {
  display: flex;
  grid-area: h;
  position: sticky;
  top: 0;
  z-index: 999;
  .iconContainer {
    min-width: 24px;
    min-height: 24px;
    display: flex;
    justify-items: center;
    align-items: center;
    .backArrow {
      min-width: 10px;
      min-height: 10px;
      margin-left: 8px;
      border-bottom: 2px solid #979797;
      border-left: 2px solid #979797;
      transform: rotate(45deg);
    }
  }
  .headerColor {
    background-color: $palette_primary__main;
    color: $palette_text__secondary;
  }
  width: 100%;
  .logo {
    margin: 0px 19px;
  }
  .grow {
    flex-grow: 1;
  }
  .info__wrapper {
    margin-left: 10%;
  }

  .title {
    display: none;
    /*
    [theme.breakpoints.up("sm")]: {
    display: block;
  }
  */
  }
  .search {
    flex-grow: 1;
    position: relative;
    border-radius: 90px;
    background-color: fade(#263238, 0.15);
    &:hover {
      background-color: fade("#263238", 0.25);
    }
    margin-right: $spacing_unit * 2;
    margin-left: 0;
    width: 100%;
    /*
    [theme.breakpoints.up("sm")]: {
    margin-left: $spacing_unit * 3;
    width: auto;
  }
  */
  }
  .searchIcon {
    width: $spacing_unit * 9;
    height: 100%;
    position: absolute;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .inputRoot {
    color: inherit;
    width: 100%;
    margin-left: 25px;
  }
  .inputInput {
    padding-top: $spacing_unit;
    padding-right: $spacing_unit;
    padding-bottom: $spacing_unit;
    padding-left: $spacing_unit * 10;
    transition: 0.2s; /*theme.transitions.create("width"),*/
    width: 100%;
    /*
    [theme.breakpoints.up("md")]: {
    width: 200;
  }
  */
  }
  .sectionDesktop {
    display: none;
    /*
    [theme.breakpoints.up("md")]: {
      display: flex;
    }
    */
    .userBlock {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    .menuTextBox {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .sectionMobile {
    display: flex;
  }
  .orangeAvatar {
    margin-left: auto;
  }
  .headerRewardPoint {
    margin-left: 20px;
    margin-right: 10px;
    line-height: 1.2;
  }

}
.userMenu {
  cursor: default !important;
  flex-direction: column;
  height: 150px !important;
  &:hover {
    background-color: #FFF !important;
  }
  .name {
    padding-top: 10px;
    font-size: 15px;
  }
  .email {
    font-size: 13px;
  }
  .button {
  }
}
