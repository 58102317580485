@import "src/styles/variables.scss";
@import "src/styles/typography.scss";


.imageExplorer{
  @include body1;
  display: flex;
  flex-direction: column;
  width: 320px;
  height: 600px;
  margin-left: 20px;
  box-shadow: 0px 5px 5px 3px rgba(0, 0, 0, 0.1), 0px 5px 10px -5px rgba(0, 0, 0, 0.3);
  padding: 15px;
  .selectRow {
    display: flex;
    align-items: center;
  }
  .help {
    background-image: url('../../static/imgs/help.png');
    background-position: center;
    margin-left: auto;
    cursor: pointer;
  }
  button {
    margin: 20px 0;
    max-width: 100%;
    text-transform: none;
  }
  .imgGrid {
    overflow-y: auto;
    display: flex;
    .imgContainer {
      width: 100%;
      height: auto;
      background-image: url('https://sivator.com/uploads/posts/2017-03/1490362936_1490318100_16.jpg');
      object-fit: cover;
      background-size: cover;
      background-repeat: no-repeat;
      margin-bottom: 5px;
    }
    .deleteRow {
      display: flex;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.3);
      color: white;
      width: 100%;
      height: 30px;
      padding: 0 10px;
      .deleteIcon {
        background-color: white;
        mask-image: url('../../static/imgs/delete.svg');
        margin-left: auto;
        cursor: pointer;
      }
    }
    .deleteRow:hover {
      transition: background-color 1s;
      background-color: rgba(0, 0, 0, 0.7);
    }
    .column1 {
      width: 50%;
      margin-right: 2.5px;
    }
    .column2 {
      width: calc(50% + 3px);
      margin-left: 2.5px;
      margin-right: 3px;

    }
    img {
      width: 100%;
    }
  }
  .imgGrid::-webkit-scrollbar {
      width: 5px;
  }
  .imgGrid::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);

  }
  .imgGrid::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
    border-radius: 5px;

  }
}
