.files {
  &-attachments {
    &__list {
      list-style: none;
      display: flex;
      flex-flow: column nowrap;
    }
    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
    }
  }
}