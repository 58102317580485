@mixin styledSVG($color) {
  svg {
    color: $color;
  }
}

@mixin gap($gap) {
  :first-child {
    margin: 0 $gap/2 0 0;
  }
  :last-child {
    margin: 0 0 0 $gap/2;
  }
}

$createdColor: #0277bd;
$approvedColor: #f9a825;
$startedColor: #00796b;
$problemColor: #d50000;
$failedColor: #263238;
$canceledColor: #979797;
$completedColor: #979797;

$gap: 8px;

$projectCardNormalGrid: "h h h h h h" "t t t t t t" "b b b b b b" "g g g g g g" "bt bt bt bt bt bt";

$projectCardWarningGrid: "w w w w w w" "h h h h h h" "t t t t t t" "b b b b b b"
  "g g g g g g" "bt bt bt bt bt bt";

.project_card__warning {
  display: grid;
  grid-template-areas: $projectCardWarningGrid;
}

.project_card__normal {
  display: grid;
  grid-template-areas: $projectCardNormalGrid;
}

.project_card {
  max-width: 300px;
  justify-self: center;
  width: 100%;
  border-radius: 10px !important;
  box-shadow: 0px 4px 8px rgba(176, 190, 197, 1) !important;
  min-height: 374px;
  .control-btn {
    grid-area: bt;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .openBtn {
    flex-grow: 1;
    margin-bottom: 25px;
  }
  .warning {
    padding: 8px 16px;
    grid-area: w;
    min-height: 40px;
    text-align: center;
    color: $problemColor;
    background-color: lighten($problemColor, 50%);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header {
    font-size: 1.2rem;
    padding: 8px 16px;
    grid-area: h;
    display: flex;
    width: auto;
    height: 70px;
    justify-content: space-between;
    span {
      max-height: 11px;
      margin: 0 $gap/2;
    }
    @include gap($gap);
    .projectCardButtons{
      display: flex;
      justify-content: flex-end;
    }

    .headerMoreIcon {
      width: 34px;
      height: 34px;
    }
    .projectInfo {
      text-align: left;
      .fullName {
        margin: 0;
        white-space: nowrap;
      }
      .date {
        margin: 0;
        margin-top: 20px;
        white-space: nowrap;
      }
    }
  }
  .title {
    padding: 8px 16px;
    grid-area: t;
  }
  .project_contract {
    padding: 8px 16px;
    grid-area: c;
    display: flex;
    @include gap($gap);
  }
  .status {
    padding: 8px 16px;
    grid-area: s;
  }
  .graph {
    padding: 8px 16px;
    grid-area: g;
  }

  .body {
    padding: 0;
    grid-area: b;
    display: flex;
    flex-direction: column;
    .button {
      padding: 0;
      @include gap($gap);
    }
    .created {
      @include styledSVG($createdColor);
      color: $createdColor;
    }
    .approved {
      @include styledSVG($approvedColor);
      color: $approvedColor;
    }
    .started {
      @include styledSVG($startedColor);
      color: $startedColor;
    }
    .problem {
      @include styledSVG($problemColor);
      color: $problemColor;
    }
    .failed {
      @include styledSVG($failedColor);
      color: $failedColor;
    }
    .canceled {
      @include styledSVG($canceledColor);
      color: $canceledColor;
    }
    .completed {
      @include styledSVG($completedColor);
      color: $completedColor;
    }
    .active {
      @include styledSVG($startedColor);
      color: $startedColor;
    }
  }
}

.epic {
  margin: 20px 20px;

  .main-data {
    display: flex;
    flex-direction: row;
    .col {
      display: flex;
      flex-direction: column;
    }
    .field-text {
      flex-grow: 0.8;
      margin: 0 10px 0 0;
    }
    .field-dropbox {
      flex-grow: 0.2;
      margin: 0 0 0 10px;
      min-width: 158px;
    }
  }
  .select {
    display: flex;
    flex-direction: row;
  }
  .field {
    margin: 10px 0;
  }
  .simpleChip {
    margin: 0 20px 0 0 !important;
  }
  .epicControl {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    .epicButton {
      margin: 0 20px;
      display: flex;
      text-align: left;
      height: 50px;
      font-size: 0.675rem;
      svg {
        margin: 0 10px 0 0;
      }
      span {
        text-transform: None;
      }
    }
  }
  .subtask-list {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    .list:hover {
      background-color: lightgray;
    }
    .control {
      display: flex;
      flex-direction: row;
      //justify-content: ;
    }
  }
}
.poll {
  display: flex;
  flex-direction: column;
  width: 100%;
  .input {
    margin: 10px 0;
  }
  .question {
  }
  .answer {
    margin: 0 0 0 20px;
  }
  .row {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .chips {
    display: flex;
    flex-direction: row;
    width: 100%;
    .chip {
      display: flex;
      flex-direction: row;
      min-width: 30px;
      justify-content: space-between;
      align-items: center;
      margin: 10px 0;
    }
    .label {
    }
    .button {
    }
  }
}

.drugs {
  display: flex;
  flex-direction: column;
  width: 100%;
  .row {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    width: 100%;
  }
  .column {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    margin: 10px 0;
    align-items: flex-start;
  }
  .autocompletesInput {
    width: 100%;
  }
}
.geo {
  display: flex;
  height: 100%;
  width: 100%;
  margin: 15px 0;
  flex-direction: row;

  .icon {
    margin: 0 10px 0 0;
  }
  .color {
    color: #00796b;
  }
  strong {
    color: #00796b;
  }
  .button {
    width: 100%;
    padding: 5px 0;
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    text-transform: none;
  }
  .row {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  .col {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .reverse {
    flex-direction: column-reverse;
  }
  //.inner {
  //  margin: 10px 20px;
  //}
  .remove {
    color: #d50000;
    align-self: flex-end;
  }
  .task-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    .label {
      display: flex;
      flex-grow: 1;
    }
  }
  .title {
  }
  .geo_title {
  }
  .done {
    color: #00796b;
  }
  .error {
    color: red;
  }
}
