.import_from_excel__wrapper {
	.import{
		&__button {
			background-color: rgb(250, 250, 250);
			border: none;
			color: #00796b;
			cursor: pointer;
			margin-left: 50px;
			outline: none;
			&:disabled {
				color: #979797;
				cursor: not-allowed;
			}
		}
		&__input {
			input {
				display: none;
			}
			div {
				color: #979797;
				cursor: pointer;
				font-size: 30px;
				display: flex;
				margin: 10px;
				align-items: center;
				justify-content: center;
				width: 100px;
				height: 70px;
				border-radius: 10px;
				border: dashed 1px #979797;
				&:hover {
					transform: scale(1.05);
					transition: 0.3s;
				}
			}
		}
	}
	.import_table {
		overflow: auto;
		margin-top: 15px;
		div {
			display: flex;
			width: 100%;
			margin-bottom: 10px;

			div {
				margin: 0;
				padding-left: 10px;
				flex-grow: 1;
				font-size: 11px;
				max-width: 100px;
				min-width: 100px;
				align-items: center;
				&.empty {
					color: #bbb;
					font-style: italic;
				}
				&.address {
					max-width: 200px;
					min-width: 200px;
				}
				&.__state {
					border-radius: 100px;
					margin-right: 20px;
					max-width: 16px;
					min-width: 16px;
					height: 16px;	
				}
				&.error {
					background-color: #ff000011;
					border-radius: 10px;
					&.__state {
						background: #ff000099;
						transform: scale(0.6);
					}
				}
				&.imported {
					&.__state {
						background: #00796b;
					}
				}
			}
		}
	}
}