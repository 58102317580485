@import "src/styles/variables.scss";

.inputBorderColor {
  // display: flex;
  // flex-direction: column;
  fieldset{
    border-color: $palette_primary__light !important;
  }
}
.preview {
  .categories {
    display: flex;
    * + * {
      margin-left: 10px;
    }
  }
  img {
    width: 640px;
  }
  .colorTitle {
    background: #00796B;
    border-radius: 3px;
    color: white;
    padding: 0 10px;
    text-transform: uppercase;
    width: max-content;
    margin-top: 30px;
  }
  .react-mde {
    border: none;
    width: 640px;
    min-height: none;
    .mde-header {
      display: none;
    }
  }
  .buttonRow {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0;
    button{
      text-transform: none;
    }
    button:last-child{
      margin-left: 10px;
      max-width: none;
    }
  }
}
