@import "../../styles/variables.scss";
@import "../../styles/typography.scss";
/*from ButtonFubCreate.jsx & buttonPopperListItem.jsx*/

@mixin black_big_text {
  font-size: 50px;
  color: black;
  font-weight: bold;
}

button {
  margin: $spacing_unit;
  .extendedIcon {
    margin-right: $spacing_unit;
  }

}

/*from ListButton.jsx*/
.sidebar {
  .button {
    opacity: 0.5;
    .toolTipMessage {
      visibility: hidden;
    }
    &.active {
      opacity: 1
    }
  }
  .text {
    @include typo__subtitle2;
  }
  .icon {
    color: $palette_controlAction__disable;
    min-width: 24px;
    min-height: 24px;
  }
  .button:hover {
    opacity: 1;
    .toolTipMessage {
      visibility: visible;
      position: fixed;
      left: 72px;
      background-color: #979797;
      color: #FFF;
      padding: 3px 10px;
      border-radius: 3px;
    }
  }
  .icon__active {
    color: $palette_controlAction__active;
  }
  button {
    margin: 9px 16px;
    width: auto;
    max-width: 188px;
    padding: 8px;
    border-radius: 10px;
    /*
    [theme.breakpoints.up("sm")]: {
    margin: 9px 16px !important;
  }
  [theme.breakpoints.up("xs")]: {
  margin: 9px 8px;
}
*/
}
.button__active {
  background-color: $palette_controlAction__activeBG;
}
}
