.modal {
  &-number {
    width: 40vw;
    &__title {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      color: #808080;
      font-size: 20px;
      margin-bottom: 24px;
    }
    & input, label {
      font-size: 16px;
    }
    &__group {
      margin-top: 17px;
      display: flex;
      justify-content: flex-end;
      flex-wrap: nowrap;
    }

    &__btn {
      cursor: pointer;
      padding: 10px 20px;
      border: 0;
      border-radius: 5px;

      &_confirm {
        background-color: #00796B;
        color: #FFFFFF;
        &:hover {
          background-color: #005347;
        }
      }
      &_cancel {
        border: 1px solid #00796B;
        color: #00796B;
        margin-right: 14px;
        background-color: #FFFFFF;
        &:hover {
          background-color: #d2d2d2;
        }
      }
    }
  }
}
