.pharmacy__wrapper {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  margin-left: 24px;
  width: 656px;
  padding-bottom: 100px;
  .tipRow {
    font-size: 14px;
    margin-top: 32px;
    .starSymbol {
      color: red;
    }
  }
  .row {
    display: flex;
    .chipsRow {
      .radioChip {
        margin-right: 10px;
        cursor: pointer;
        background: rgba($color: #00796B, $alpha: 0.2);
        color: #263238;
      }
      .active {
        background: #00796B;
        color: white;
      }
    }
    .validateStaff {
      display: flex;
      align-items: center;
      white-space: nowrap;
      margin-left: 28px;
      .icon {
        margin-right: 5px;
        display: hidden;
      }
    }
    .contactsRow {
      // margin-top: 24px;
      div {
        margin-right: 9px;
      }
      :nth-child(3) {
        margin-right: 0;
      }
    }
  }
  .buttonsRow {
    white-space: nowrap;
    display: flex;
    justify-content: flex-end;
    height: 40px;
    .cancelButton {
      border-color: #d32f2f;
      color: #d32f2f;
      margin-right: 8px;
    }
    .addButton {
      background-color: #00796B;
      color: white;
      min-width: 252px;
    }
    button {
      text-transform: none;
      width: max-content;
    }
  }
  .focusedColor {
    fieldset {
      border-color: #00796B !important;
    }
  }

  .listIcon {
    margin-right: 32px;
    align-self: center;
    rect {
      display: none;
    }
  }
  .radioGroup {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 4px;
    label {
      margin-right: 24px;
    }
  }
  .map {
    width: calc(100% - 55px);
    height: 144px;
    margin-left: 55px;
    margin-top: -8px;
    // ymaps {
    //   width: 100%;
    //   // height: 144px;
    // }
  }
}
