
.manufacturers__wrapper {
  .table__head {
    display: grid;
    grid-template-columns: 40% 35% 25%;
    padding: 13px;
    border-bottom: 1px dotted rgba(#263238, 0.24);
  }
  .table__body {
    .manufacturer__row {
      display: grid;
      grid-template-columns: 40% 35% 25%;
      padding: 13px;
      border-bottom: 1px dotted rgba(#263238, 0.24);
      cursor: pointer;
      &:hover {
        background-color: rgba($color: #979797, $alpha: 0.1);
        transition: 0.5s;
      }
    }
  }
}