.modal {
  &-confirm {
    width: 40vw;
    min-width: 300px;
    &__title {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      color: #808080;
      font-size: 20px;
    }
    &__message {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      text-align: center;
      letter-spacing: 0.1px;
      border-radius: 5px;
      box-shadow: none;
      padding: 10px;
      word-wrap: break-word;
      margin-top: 13px;
      &::placeholder {
        color: #bfbfbf;
      }
    }
    &__group {
      margin-top: 17px;
      display: grid;
      justify-content: flex-end;
      grid-gap: 20px;
      grid-auto-flow: column;
    }
  }
}