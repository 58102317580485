@import "../../styles/typography.scss";

.table {
  position: relative;
  .items {
    margin-bottom: 60px;
    // min-height: 100vh;
  }
  .item {
    border-bottom: solid 0.1px #cbcecf;
    padding: 15px 15px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    word-break: break-word;
    @include body1;
    &:hover:not(.headline_text) {
      cursor: pointer;
      background-color: #efefef;
    }
  }
  .headline_text {
    font-weight: bold;
  }
  .tableInfo {
    background: white;
    position: fixed;
    width: calc(100% - 57px);
    bottom: 0;
    padding: 15px 15px;
    display: grid;
    //grid-template-columns: 4fr 1fr 0.1fr 0.1fr 0.1fr;
    grid-template-columns: 4fr 0.3fr 0.3fr 0.3fr;
    @include body3;
    .current {
      text-align: center;
    }
    .nextPage {
      cursor: pointer;
      width: 100%;
      background-repeat: no-repeat;
      height: 17px;
      background-image: url(../../static/imgs/keyboard_arrow_right_.png);
      background-position: center center;
      padding: 0 10px;
    }
    .previousPage {
      cursor: pointer;
      width: 100%;
      height: 17px;
      background-image: url(../../static/imgs/keyboard_arrow_right_.png);
      background-position: center center;
      background-repeat: no-repeat;
      float: right;
      transform: rotate(180deg);
      padding: 0 10px;
    }
  }
}
