.wizard {
  //overflow: scroll;
  //overflow-x: hidden;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  .tasks{
    flex-grow: 1;
    overflow: scroll;
    overflow-x: hidden;
    max-height: 448px;
  }
  .control {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .controlEpic {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .button {
      margin: 0 20px;
      span {
        text-transform: None;
      }
    }
  }
}
