@import "../../styles/variables.scss";

/* from SnackbarsProgress.jsx */
.snackbars {
  .success {
    background-color: $palette_green__600;
  }
  .error {
    background-color: $palette_error__dark;
  }
  .info {
    background-color: $palette_primary__dark;
  }
  .warning {
    background-color: $palette_amber__700;
  }
  .icon {
    font-size: 20;
  }
  .iconVariant {
    opacity: 0.9;
    margin-right: $spacing_unit;
  }
  .message {
    display: flex;
    align-items: center;
  }
  .wrapper {
    margin: $spacing_unit;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .buttonSuccess {
    background-color: $palette_green__500;
    &:hover {
      background-color: $palette_green__700;
    }
  }
  .fabProgress {
    color: $palette_green__500;
    position: absolute;
    top: -6;
    left: -6;
    z-index: 1;
  }
}
