.user_statistics {
  .courseInfo__row {
    display: flex;
    margin-bottom: 20px;
    & .info__block:nth-child(2) {
      margin: 24px 24px 0;
    }
    & .info__block {
      display: flex;
      flex-direction: column;
      width: 100%;
      background-color: #FFF;
      box-shadow: 0px 0px 15px rgba(0, 122, 106, 0.05);
      border: 1px solid #F7F7F7;
      border-radius: 5px;
      margin-top: 24px;
      padding: 24px;
      & .value {
        font-size: 48px;
        color: #404040;
        text-align: center;
      }
      .workPlaces__row {
        display: flex;
        margin-bottom: 12px;
        p {
          width: 100%;
        }
      }
      .institution {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
      }
      .row {
        width: 100%;
        .autocomplete-input {
          margin-right: 24px;
        }
      }
    }
  }
  & .course__row {
    display: flex;
    align-items: center;
    height: 88px;
    border-bottom: 1px dashed #979797;
    justify-content: space-between;
    .block {
      display: flex;
      justify-content: center;
      align-items: center;
      .certificate {
        width: 36px;
        height: 36px;
        background: url(../static/imgs/download.svg) no-repeat center;
        transition: opacity 0.25s ease-in-out;
        &:hover {
          opacity: 0.3
        }
      }
    }
    .link {
      display: flex;
      align-items: center;
      cursor: pointer;
      &:hover {
        div.withIcon {
          color: white;
          div.copyIcon { opacity: 1; }
        }
      }
      div.linkIcon {
        background: #d7d7d7;
        background-position: center;
        width: 36px;
        height: 36px;
        border-radius: 30px;
        margin-right: 20px;
        background-image: url(../static/imgs/link.png);
      }
      div.withIcon {
        cursor: pointer;
        display: flex;
        div.copyIcon {
          background-position: center;
          margin-left: 10px;
          opacity: 0;
          width: 20px;
          height: 20px;
          border-radius: 20px;
          background-image: url(../static/imgs/copy.png);
          &.copied {
            animation-name: poomph;
            animation-duration: 0.55s;
            background-size: 80%;
            background-repeat: no-repeat;
            background-image: url(../static/imgs/checked.png);
          }
        }
      }
    }
  }
}
