@import "../../styles/typography.scss";

.comments {
  overflow-x: hidden;
  overflow-y: auto;
  .comment {
    margin-left: 20px;
    gap: 15px;
    display: grid;
    grid-template-columns: repeat(8 , 1fr);
    grid-template-areas:
    "a c c c c c c c"
    ". s s s s s s s";
    .sub {
      grid-area: s;
      margin-left: -20px;
    }
    .userAvatar_container {
      grid-area: a;
      display: flex;
      justify-content: center;
      .userAvatar {
      }
    }
    .firstRow {
      display: flex;
      align-items: baseline;
      * + * {
        margin-left: 10px;
      }
    }
    .userName, .addComment {
      @include headline_text;
    }
    .docName, .fullDate, .personStatus {
      color: $palette_text__secondary !important;
    }
    button {
      padding-top: 0;
      padding-bottom: 0;
    }
    .commentBody {
      padding-top: 5px;
      p {
        font-size: 17px;
        color: rgba(0,0,0,0.9);
      }
    }
    .docLink  {
      padding-top: 5px;
      a {
        @include headline_text;
        text-decoration: none;
      }
    }
    .cancel {
      color: $palette_secondaryAccent__dark;
      text-transform: none;
      font-size: 16px;
      font-weight: 600;
    }


    .textInfo {
      grid-area: c;
      flex-direction: column;
    }
    .reduceDate {
      margin-bottom: 20px;
    }
    .firstRow {
      & span {
        padding-right: 15px;
      }
    }
    .lastRow {
      padding-right: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
.logs {
  .enterComment {
    display: flex;
    margin-top: auto;
    width: 100%;
    margin-bottom: 50px;
    align-self: center;
    justify-content: center;
    align-items: center;
    .inputStyle {
      font-size: 18px;
    }
  }
  .icon {
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    margin: 10px;
  }
  .attach-file {
    background-image: url(../../static/imgs/attach-file.svg);
    opacity: 0.6;
  }
  .send {
    background-image: url(../../static/imgs/send.png);
  }
}
