@import "../styles/typography.scss";

.links {
  height: calc(100vh - 350px);
  // height: 100px;
  overflow: auto;
  .links_header {
    
  }
  .invite {
    height: 80px;
    display: flex;
    padding: 0 20px;
    .block {
      display: flex;
      justify-content: center;
      align-items: center;
      &.course {
        width: 200px;
      }
    }
    .certificate {
      width: 36px;
      height: 36px;
      background: url(../static/imgs/download.svg) no-repeat center;
      transition: opacity 0.25s ease-in-out;
      &:hover {
        opacity: 0.3
      }
    }
    .link {
      flex-grow: 1;
      justify-content: flex-start;
      cursor: pointer;
      &:hover {
        div.withIcon {
          color: white;
          div.copyIcon { opacity: 1; }
        }
      }
      div.linkIcon {
        background: #d7d7d7;
        background-position: center;
        width: 36px;
        height: 36px;
        border-radius: 30px;
        margin-right: 20px;
        background-image: url(../static/imgs/link.png);
      }
      div.withIcon {
        cursor: pointer;
        display: flex;
        div.copyIcon {
          background-position: center;
          margin-left: 10px;
          opacity: 0;
          width: 20px;
          height: 20px;
          border-radius: 20px;
          background-image: url(../static/imgs/copy.png);
          &.copied {
            animation-name: poomph;
            animation-duration: 0.55s;
            background-size: 80%;
            background-repeat: no-repeat;
            background-image: url(../static/imgs/checked.png);
          }
        }
      }
    }
    .title {
      flex-grow: 2;
    }
    .user {
      flex-grow: 4;
      min-width: 350px;
    }
    .status {
      flex-grow: 1;
      justify-content: flex-start;
      &.without_access { color: #979797; }
      &.process { color: #F2994A; }
      &.complete { color: #00796B; }
      &.failed { color: #D50000; }
    }
    .date {
      flex-grow: 2;

    }
  }
  .invite + .invite {
    border-top: 1px solid #eaeaea;
  }
}
