@keyframes swipe {
  from { transform: translateY(1000px);}
  to { transform: translateY(0px);}
}
@keyframes fadein {
  from { opacity: 0 }
  to { opacity: 1 }
}

.edit_modal_wrapper {
  animation-name: fadein;
  animation-duration: 0.3s;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.2);
  display: flex;
  justify-content: center;
  align-items: center;

  .modal {
    opacity: 1 !important;
    width: 300px;
    border-radius: 7px;
    padding: 30px;
    border: 1px solid rgba(0,0,0,0.3);
    background: white;
    animation-name: swipe;
    animation-duration: 0.5s;

    div.projects {
      margin-bottom: 50px;
    }
  }
}
