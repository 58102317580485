
.documentList__wrapper {
  .table__head {
    display: grid;
    grid-template-columns: 40% 35% 15% 10%;
    padding: 13px;
    border-bottom: 1px dotted rgba(#263238, 0.24);
  }

  .document {
  	display: grid;
    grid-template-columns: 40% 35% 15% 10%;
    transition: 0.3s;
    padding: 13px;
    border-bottom: 1px dotted rgba(#263238, 0.24);
    .date {
      font-family: Raleway;
      font-weight: 600;
    }
    p {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      padding: 0 3px;
    }

    &.deleting {
      transform: translateX(-20px);
      opacity: 0.4;
    }

  	.actions {
  		display: flex;
  		justify-content: space-between;

  		.action {
  			width: 1fpx;
  			height: 1fpx;
        margin-left: 5px;
        cursor: pointer;
        &:hover {
          transition: 0.3s;
          transform: scale(1.1);
        }
  		}
  	}
  }
}


