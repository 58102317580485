@import "../styles/variables.scss";
@import "../styles/typography.scss";

.autocompleteInput {
  position: relative;
  &.withList {
    .autocomplete {
      display: none;
    }
  }
  .autocomplete {
    max-height: 100px;
    overflow-y: auto;
    border: 2px solid #00796b;
    border-top: 1px solid rgba(0,0,0,.1);
    padding: 10px 0 10px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background: white;
    position: absolute;
    width: 100%;
    margin-top: -15px;
    z-index: 9;
    p {
      padding: 7px 14px;
      cursor: pointer;
      &:hover {
        background: rgba(0,0,0,0.1);
      }
    }
  }
}
.materialsPreview {
  width: 64px;
  height: 64px;
}
.materials__wrapper {
  .categories { display: flex; * + * { margin-left: 10px; }}
  .previewCard {
    margin-left: 20px;
    width: 332px;
    height: max-content;
    .infoRow {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
    }
    .text {
      width: 300px;
      padding: 16px 13px 0 13px;
    }
    .help {
      background-image: url('../static/imgs/help.png');
      background-position: center;
      margin-left: 10px;
      cursor: pointer;
    }
    img {
      width: auto;
      width: 300px;
    }
  }
  .edit__wrapper{
    display: flex;
    flex-direction: column;
    width: 640px;
    .highlight {
      color: #D72638;
    }
    .chipsInput {
      display: flex;
      flex-direction: column;
    }
    .inputRow {
      display: flex;
      align-items: center;

      .photoInput__wrapper {
        background-color: #EEF2F3;
        margin: 25px 0 20px 20px;
        p {
          color: #D72638;
          margin-right: -5px;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border: solid 1px rgba(0, 0, 0, 0.23);
        }
        label {
          display: flex;
          width: 85px;
          height: 85px;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
        .photoInputIcon {
          background-image: url('../static/imgs/add_a_photo.png');
        }
        input {display: none;}
      }
    }
    .react-mde {
      margin: 15px 0;
      .mde-tabs {
        display: none;
      }
    }
    .sourceRow {
      display: flex;
      align-items: center;
      label {
        background-color: white;
      }
      .sourceIcon {
        background-image: url('../static/imgs/source.png');
        margin-right: 20px;
      }
      .createIcon {
        background-image: url('../static/imgs/create.png');
        margin-left: 20px;
        background-position: center;
        cursor: pointer;
      }
    }
    .linkRow {
      display: flex;
      align-items: center;
      .linkIcon {
        background-image: url('../static/imgs/link.png');
        margin-right: 20px;
      }
    }
    .buttonRow {
      display: flex;
      margin: 20px 0;
      button{
        text-transform: none;
      }
      button:first-child{
        margin-right: auto;
        max-width: none;
        color: #3F51B5;
      }
      button:last-child{
        margin-left: 10px;
        max-width: none;
      }
    }
  }

}
