.filter {
  white-space: nowrap;
  width: 220px; /*drawerWidth*/
  transition: 0.2s;
  top: 64px !important;
  &.closed {
    overflow-x: hidden;
    transition: 0.2s;
    /*
    transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
    })
    */
    width: 0;
    padding: 0;
    /*
    [theme.breakpoints.up("sm")]: {
    width: 0
    }
    */
  }
}
