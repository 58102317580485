

.dropbox-container{
  display: flex;
  height: 100%;
  padding: 14px 0;
}


.dropbox {
  width: 100%;
  height: 100%;
  min-width: 80px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #00796B;
  border: solid 1px #00796B;
  border-radius: 12px;
  //background-color:  rgba(189, 214, 239, 0.5);
  transition: all .2s ease-in-out;
}

.dropbox:hover {
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-color:rgba(222, 255, 229, 0.75);
}

.dropbox.dragover {
  width: 420px;
  height: 170px;
  line-height: 170px;
  cursor: pointer;
}

.dropbox-info{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.dropbox-image{
  width: 140px;
  height:  140px;
  z-index: 6000;
}