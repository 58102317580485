@import "../../styles/variables.scss";
@import "../../styles/typography.scss";

.toolbar {
  padding-right: 0px;
  transition: 0.2s;
  background-color: $palette_primary__main;
  justify-content: space-between;
  &.withFilter {
    padding-right: 220px !important;
  }
  button {
    font-weight: 100;
    border: 0;
    text-decoration: none;
    background-color: #fff;
    cursor: pointer;
    padding: 20px;
    margin: 0px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    @include button_outline;
    &:hover {
      background-color: #00796B11;
    }
  }
  color: $palette_secondary__main;
  spacer {
    flex: 1 1 100%;
    padding: $spacing_unit * 2 +'px';
  }
  .actions {
    color: $palette_text__secondary;
  }
  title {
    flex: 0 0 auto;
  }
  .title {
    font-weight: 600;
    letter-spacing: 0.5px;
  }
  @include h6;
}
