.modal__wrapper {
  top: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  width: 100vw;
  overflow: hidden;
  height: 100vh;
  background: rgba(0,0,0,0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  .modal {
    position: absolute;
    padding: 20px 30px;
    background: white;
    opacity: 1 !important;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    div.actions {
      position: fixed;
      // width: 80vw;
      // background-color: rgb(241,241,241);
      bottom: 10vh;
      padding: 10px 0 10px 30px;
      margin-left: -30px;
    }
    div.close {
      cursor: pointer;
      position: absolute;
      width: 20px;
      height: 20px;
      top: 10px;
      right: 10px;
      background-image: url(../../static/imgs/close.png);
      background-position: center
    }

    .task {
      display: flex;
      flex-direction: column;
      position: relative;
      overflow-y: scroll;
      overflow-x: hidden;
      width: 70vw;
      max-width: 800px;
      padding-bottom: 400px;
      p.header {
        font-size: 23px;
        margin-top: 30px;
        margin-bottom: 10px;
        &.big {
          font-size: 25px;
          margin-top: 5px;
        }
      }
      div.line {
        display: flex;
        justify-content: space-between;
      }
      div.col {
        width: calc(100% - 120px);
        display: flex;
        flex-direction: column;
      }
      div.photo {
        width: 100px;
        height: 100px;
        background: black;
      }
      div.select {
        display: flex;
        flex-direction: row;
      }
    }
  }
}
