.image {
  &-fullScreen {
    &__wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100vw;
      height: 100vh;
    }
    &__image {
      max-height: 70vh;
      max-width: 70vw;
    }
  }
}