$padding: 15px;

.modal_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 35px;
  .modal {
    grid-area: m;
    background-color: white;
    display: flex;
    border-radius: 32px;
    padding: 0 25px;
    flex-direction: column;
    width: 90vw;
    height: 90vh;
    max-width: 704px;
    .title {
      display: grid;
      height: 48px;
      align-items: center;
      gap: 37px;
      grid-template: 1fr / 14px 1fr;
      .close {
        padding: 0;
      }
    }
    .tabControl {
      height: 56px;
      display: grid;
      grid-template-areas: ". . . t t t t . . .";
      span {
        text-transform: capitalize;
      }
      .tabs {
        grid-area: t;
      }
    }
    ._search {
      height: 48px;
      background: rgba(#263238, 0.24);
      box-shadow: 0px 4px 8px rgba(84, 110, 122, 0.24);
      border-radius: 90px;
      padding: 0 19px;
      margin: 16px 0;
      display: grid;
      grid-template: 1fr / 14px 1fr;
      align-items: center;

      gap: 19px;
    }
    .linkContractContainer{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 0 -25px 15px -25px;
      padding: 0 25px;
      box-shadow: 0px 4px 8px rgba(84, 110, 122, 0.24);
      .linkContractTitle{
        align-self: center;
        justify-self: center;
        text-wrap: none;
      }
    }
    .contentControl {
      display: grid;
      height: 56px;
      align-items: center;
      justify-items: center;
      grid-template: 1fr / 1fr 1fr 1fr;
      margin: 0 -25px 15px -25px;
      padding: 0 25px;
      box-shadow: 0px 4px 8px rgba(84, 110, 122, 0.24);

      .actions {
        align-self: flex-end;
        .button {
          z-index: 300;
          position: relative;
          top: 20px;
        }
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      .column {
        overflow-y: scroll;
        overflow-x: hidden;
        max-height: 450px;
      }
      .row {
        display: grid;
        gap: 32px;
      }
      .general {
      }
      .document {
        display: grid;
        gap: 32px;
        grid-template: 1fr / 1fr 1fr;
        margin: 0 0 16px 0;
        flex-grow: 1;
      }
      .logs {
        display: grid;
        gap: 32px;
        grid-template: 1fr / 1fr;
        margin: 0 0 16px 0;
        flex-grow: 1;
      }
      .link-contract {
        display: grid;
        gap: 32px;
        grid-template: 30vh / 1fr 1fr;
        flex-grow: 1;
      }
      .project {
        display: grid;
        gap: 32px;
        grid-template: 30vh / 1fr;
        flex-grow: 1;
      }
    }
  }
}
.tabControl {
  .tabs {
    .flexContainer {
      height: 100%;
    }

    .centered {
    }

    .scroller {
    }

    .fixed {
    }

    .scrollable {
    }

    .scrollButtons {
    }

    .scrollButtonsAuto {
    }

    .indicator {
      background-color: #00796b;
    }
    .tab {
      max-width: 120px;
    }
    .labelIcon {
    }
    .textColorInherit {
    }
    .textColorPrimary {
      color: #263238;
    }
    .textColorSecondary {
    }
    .selected {
      color: #00796b;
    }
    .disabled {
    }
    .fullWidth {
    }
    .wrapper {
    }
    //.labelContainer {
    //  color:#263238
    //}
    .label {
    }
    .labelWrapped {
    }
  }
}

.badge {
  span {
    color: white;
    background-color: red;
  }
}
