.form {
  display: grid;
  gap: 24px;
  //grid-template: repeat(auto-fit, 56px) / 1fr;
  //height: 100%;
  flex-grow: 1;
  padding: 12px 0;

  .row {
    grid-template: 1fr / 40px repeat(auto-fit, minmax(200px, 1fr));
    display: grid;
    gap: 24px;
    button{
      min-height: 56px;
      width: 100%;
    }
  }
  .icon{
    justify-self: center;
    align-self: center;
  }
  .input {

    margin: 0;
  }
}
