
.document_log {
  gap: 15px;
  display: grid;
  grid-template-columns: repeat(8 , 1fr);
  grid-template-areas:
    "a c c c c c t t"
    ". c c c c c . ac";
  .sub {
    grid-area: s;
  }
  .type{
    grid-area: t;
    text-align: right;
    margin-right: 15px;
  }
  .actions {
    grid-area: ac;
  }
  .userAvatar_container {
    grid-area: a;
    display: flex;
    justify-content: center;
    .userAvatar {
    }
  }


  .textInfo {
    grid-area: c;
    flex-direction: column;
  }
  .reduceDate {
    margin-bottom: 20px;
  }
  .firstRow {
    & span {
      padding-right: 15px;
    }

  }
  .description {
    padding-top: 5px;
  }
  .file_state {
    span{
      text-transform: none;
      text-align: left;
      color: #00796b;
    }
  }
}
