.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  &.full {
    width: 100%;
    height: 100%;
  }
  &.line {
    height: 120px;
  }
  .loader-item {
    color: #00695c !important;
  }
}
