@import "../../styles/variables";

.profile__wrapper {
  background-color: rgb(250, 250, 250);
  padding-right: 30px;
  .profileToolbar__menu {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: 20px;
    .tabs {
      grid-column: 2/3;
      justify-content: space-evenly;
      a {
        color: #979797;
        text-decoration: none;
        &:nth-child(2) {
          margin: 0 1em;
        }
      }
      .currentTab {
        color: #00796b;
        border-bottom: 2px solid #00796b;
      }
    }
    .addButton__wrapper {
      display: flex;
      align-items: center;
      grid-column: 3/4;
      justify-self: end;
      a {
        color: #00796b;
        text-decoration: none;
      }
      svg {
        color: #00796b;
      }
    }
  }

  .autocomplete-input {
    width: 100%;
  }

  input,
  textarea {
    padding: 0 15px;
    width: 100%;
    height: 40px;
    box-shadow: 0px 0px 15px rgba(0, 122, 106, 0.05);
    background: white;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    outline: none;
    font-family: Roboto;
    font-size: 14px;
    color: #999999;

    &.modyfied {
      border-left: 5px solid #f2994a;
      padding-left: 11px;

      &:focus {
        border-color: #f2994a;
        border-left: 5px solid #f2994a;
      }
    }

    &:focus {
      border: 1px solid #339388;
      box-shadow: 0px 0px 3px #339388;
    }
  }

  textarea {
    resize: none;
    padding: 10px;
    height: 110px;
  }

  .radio_group {
    input {
      display: none;

      &:disabled + label {
        border-color: #bdbdbd;
      }
    }

    input:checked + label {
      background-color: #00796b;
      color: #fff;
    }

    input:checked:disabled + label {
      background-color: #bdbdbd;
    }

    label {
      background-color: #fff;
      border: 1px solid #00796b;
      color: #bfbfbf;
      display: inline-block;
      min-height: 36px;
      width: 50%;
      padding: 7px;
      text-align: center;

      &:hover {
        cursor: pointer;
      }
    }

    :nth-child(2) {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    :nth-child(4) {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }

  .main__button {
    background-color: #00796b;
    border: none;
    border-radius: 6px;
    color: #fff;
    min-height: 36px;
    min-width: 167px;
    padding: 8px 14px;
    font-size: 16px;
    line-height: 20px;
    white-space: nowrap;

    &.big {
      min-width: 300px;
      margin-left: 20px;
    }
    &.red {
      background-color: #f2394a;
    }

    &:disabled {
      background-color: #bdbdbd;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .save__button {
    margin-top: 20px;
    margin-bottom: 20px;
    float: right;
  }

  .row {
    display: flex;

    .info__block:nth-child(2) {
      margin: 24px 24px 0;
    }

    .gender,
    .balance {
      flex-direction: column;
    }
  }

  .full_width_card {
    flex-direction: column;
  }

  .icon {
    cursor: pointer;

    svg {
      height: 100%;
    }
  }

  .info__block {
    display: flex;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 0px 15px rgba(0, 122, 106, 0.05);
    border: 1px solid #f7f7f7;
    border-radius: 5px;
    margin-top: 24px;
    padding: 24px;

    .workPlaces__row {
      display: flex;
      margin-bottom: 12px;

      p {
        width: 100%;
      }
    }

    .institution {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
    }

    &.flex {
      &.row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }

    .row {
      width: 100%;

      .autocomplete-input {
        margin-right: 24px;
      }
    }
  }

  .contact {
    align-items: center;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr 0.5fr;
  }

  .avatar {
    &_field {
      margin-left: 12px;
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
    }
    &__upload {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
  }
  .title {
    font-size: 14px;
  }
  .secondary_text {
    color: #bfbfbf;
    margin-bottom: 12px;
  }
  .balls {
    font-size: 48px;
    line-height: 36px;
    margin-bottom: 0;
    white-space: nowrap;
  }
  .personInfo {
    justify-content: space-between;
  }
  .roleInfo {
    flex-direction: column;
  }
}

.position {
  &-profile {
    &__list {
      display: flex;
      flex-flow: nowrap column;
      margin-top: 14px;
    }
    &__row {
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 1fr auto;
      grid-column-gap: 20px;
    }
    &__notification {
      color: #388e3c;
      font-size: rem(12);
      display: grid;
      grid-column-gap: 7px;
      grid-template-columns: auto 1fr;
      &_error {
        color: #950000;
      }
    }
  }
}

.roles {
  &-profile {
    &__list {
      display: flex;
      flex-flow: nowrap column;
    }
    &__item {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #d9d9d9;
      padding-bottom: 5px;
      margin-top: 10px;
      &:last-child {
        border-bottom: none;
      }
    }
    &__control {
      display: flex;
      justify-content: flex-end;
    }
  }
}
