@keyframes openMenu {
  0% {
    opacity: 0;
    transform: translateY(-200px);
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes closeMenu {
  0% {
    opacity: 1;
    transform: translateY(0);

  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: translateY(-100px);
  }
}


.taskCreate__wrapper {
  padding-bottom: 200px;
  padding-right: 30px;
  * {
    font-family: Roboto;
    font-size: 14px;
    color: #999999;
  }
  textarea {
    resize: none;
  }

  .photoInput__wrapper {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;

    .photoInput {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
    .photoInputPreview {
      display: flex;
    }
    .previewContainer {
      position: relative;
      &:hover {
        svg {
          opacity: 1;
        }
        img {
          opacity: 0.5;
          transition: 0.3s;
        }
      }
      svg {
        opacity: 0;
        z-index: 100;
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;
        path {
          fill: black;
        }
        &:hover {
          transform: scale(1.1);
        }
      }
      img {
        width: 6vw;
        height: 9vh;
        object-fit: cover;
        border-radius: 5px;
      }
      margin-top: 15px;
      margin-right: 15px;
    }
    .photoInput + label {
      background: white;
      min-width: 6vw;
      min-height: 9vh;
      margin-top: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.2s;
      border-radius: 5px;
    }
    .photoInput + label:hover {
      cursor: pointer;
      transform: scale(1.1);
      border-radius: 10px;
    }
    label {
      div {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        font-weight: 100;
        font-size: 28px;
      }
    }
  }

  input, textarea, button, select {
    padding: 0 15px;
    width: 100%;
    height: 40px;
    box-shadow: 0px 0px 15px rgba(0, 122, 106, 0.05);
    background: white;
    border-radius: 5px;
    border: 1px solid #F7F7F7;
    outline: none;
    font-family: Roboto;
    font-size: 14px;
    color: #999999;
    &.modyfied {
      border-left: 5px solid #F2994A;
      padding-left: 11px;
      &:focus {
        border-color: #F2994A;
        border-left: 5px solid #F2994A;
      }
    }
    &:focus {
      border: 1px solid #339388;
      box-shadow: 0px 0px 3px #339388;
    }
  }
  .saveButton_row {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .save__button {
      background: rgba(0, 121, 106, 0.05);
      width: max-content;
    }
  }
}
.task__menu {
  width: 100%;
  margin-top: 15px;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "title       map"
                       "description map"
                       "description seniorStaff";
  .title {
    grid-area: title;
    height: 40px;
    display: flex;
    .icon {
      background: white;
      min-width: 40px;
      min-height: 40px;
      margin-right: 15px;
      box-shadow: 0px 0px 15px rgba(0, 122, 106, 0.05);
      border-radius: 5px;
      border: 1px solid #F7F7F7;
    }
  }
  .map__wrapper {
    grid-area: map;
    height: 200px;
    border-radius: 5px;
    border: 1px solid #F7F7F7;
    outline: none;
    &.modyfied {
      border-left: 5px solid #F2994A;
      // padding-left: 11px;
      input {
        padding-left: 35px;
        &:focus {
          border-color: #F2994A;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-left: 0;
        }
      }
    }
    .map {
      width: 100%;
      height: 80%;
      &.disabled {
        filter: grayscale(1);
      }
    }
    input {
      height: 20%;
      background-image: url(/src/static/imgs/WorkerIcon.svg);
      padding-left: 40px;
      box-shadow: 0px 0px 15px rgba(0, 122, 106, 0.05);
      border-radius: 5px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border: 1px solid #F7F7F7;
      outline: none;
      &:focus {
        border: 1px solid #339388;
        border-top: none;
        box-shadow: 0px 0px 3px #339388;
      }
    }
  }
  .description {
    grid-area: description;
    height: 200px;
    box-shadow: 0px 0px 15px rgba(0, 122, 106, 0.05);
    border-radius: 5px;
    border: 1px solid #F7F7F7;
    p {
      height: 20%;
      border-bottom: 2px solid #E5E5E5;
      padding: 10px 15px;
      background: white;

    }
    textarea {
      padding-top: 10px;
      height: 100%;
      // &:focus {
      //   // border-top: none;
      //   box-shadow: 0px 0px 3px #339388;
      // }
      // &:
    }

  }
  .seniorStaff {
    grid-area: seniorStaff;
    height: 40px;
    box-shadow: 0px 0px 15px rgba(0, 122, 106, 0.05);
    border-radius: 5px;
    z-index: 999;
    border: 1px solid #F7F7F7;
    &.modyfied {
      input {
        border-left: 5px solid #F2994A;
        padding-left: 35px;
        &:focus {
          border-color: #F2994A;
        }
      }
    }
    input {
      height: 100%;
      background-image: url(/src/static/imgs/WorkerIcon.svg);
      background-position: 10px 10px;
      background-repeat: no-repeat;
      padding-left: 40px;
    }
  }
}
.subtask__menu {
  margin-top: 30px;
  background: white;
  border: 1px solid #F7F7F7;
  box-shadow: 0px 0px 15px rgba(0, 122, 106, 0.05);
  border-radius: 5px;
  overflow: hidden;
  transition: 0.3s;
  &.opened {
    max-height: 1000px;
  }
  &.hidden {
    max-height: 40px;
  }

  .toggleOpenMenu {
    display: flex;
    z-index: 100;
    p {
      margin-top: 10px;
    }
    .icon {
      color: white;
      min-width: 20px;
      min-height: 20px;
      background-color: #DADADA;
      border-radius: 50%;
      margin: 10px 17px;
      margin-top: 10px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .subtasks__row {
    padding: 10px 2% 15px;
    display: flex;
    &.opened {
      animation-duration: 0.5s;
      animation-name: openMenu;
    }
    &.disabled {
      animation-duration: 0.5s;
      animation-name: closeMenu;
      padding: 0;
    }

    .addSubtask {
      display: flex;
      flex-wrap: wrap;
      margin-left: 2%;
      width: 13%;
      height: 18vh;
      border: 1px solid #E5E5E5;
      border-radius: 5px;

      &:first-child {
        margin-left: 0;
      }
      .type {
        width: 100%;
        height: 75%;
        border-bottom: 1px solid #E5E5E5;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 65px;
          height: 65px;
        }
        p {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 5%;
          white-space: nowrap;
        }
      }
      .plus, .counter {
        width: 50%;
        height: 25%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .plus {
        border-left: 1px solid #E5E5E5;
        cursor: pointer;
      }
    }
  }
}
