
.mypharms {
  display: flex;
}
.pharm-card {
  margin: 30px;
  border: 1px solid rgba(0,0,0,0.2);
  background: rgba(0,0,0,0.05);
  cursor: pointer;
  border-radius: 6px;
  padding: 15px 20px;
  width: 200px;
  transition: 0.1s;
  &:hover {
    background: rgba(0,0,0,0.1);
  }
  p {
    // mar
    &.value {
      font-size: 20px;
      color: black;
      line-height: 1.2;
      margin-bottom: 15px;
    }
  }
}
