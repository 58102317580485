.modal {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  padding: 20px 30px;
  background: white;
  opacity: 1 !important;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
}