.filters {
  position: relative;
  &.opened {
    div.list {
      display: block;
    }
  }
  &.active {
    button {
      background-color: rgba(0, 0, 0, 0.08);
    }
  }
  button {
    text-transform: none;
    border: 1px solid #cacaca;
    color: #cacaca;
    width: 140px;
    i {
      margin-right: 10px;
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-position: center;
      border: 0;
      background-image: url(../../static/imgs/filters.png);
    }
  }

  div.list {
    z-index: 9999;
    display: none;
    position: absolute;
    width: 220px;
    margin-top: 5px;
    padding: 5px 0;
    right: 0;
    background: white;
    border: 1px solid #fafafa;
    border-radius: 9px;
    box-shadow: 0px 5px 5px #cacaca;
    overflow: hidden;

    div {
      cursor: pointer;
      text-transform: none;
      display: flex;
      padding: 10px 15px;
      user-select: none;

      p {
        color: #263238;
      }

      &.selected {
        p {
          color: #263238;
        }
        div {
          opacity: 1;
        }
        background: #f3f3f3;
        &:hover {
          background: #eaeaea;
        }
      }

      &:hover {
        background: #fafafa;
      }

      div {
        opacity: 0.5;
        width: 20px;
        height: 20px;
        margin-right: 10px;
        background-repeat: no-repeat;
        background-position: center;

        &:hover {
          background-repeat: no-repeat;
          background-position: center;
          background-color: transparent;
        }

        &.active {
          background-image: url(../../static/imgs/active.png);
        }

        &.in_use {
          background-image: url(../../static/imgs/active.png);
        }

        &.not_used {
          background-image: url(../../static/imgs/archive.png);
        }

        &.not_started_yet {
          background-image: url(../../static/imgs/archive.png);
        }

        &.finished {
          background-image: url(../../static/imgs/passed.png);
        }

        &.used_with_success {
          background-image: url(../../static/imgs/passed.png);
        }

        &.used_with_failure {
          background-image: url(../../static/imgs/failed.png);
        }
      }
    }
  }
}