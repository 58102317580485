@import "../../../styles/typography.scss";

.tablePaperWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  /* padding: "0 20px", */
  height: 100%;
  .opened {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0 20px;
    height: 100%;
    width: 100%;
  }
  table {
    min-width: 100;
    width: 100%;
  }
  .tableWrapper {
    overflow-x: auto;
  }
  .tableHeadRow {
    @include typo__subtitle2;
  }
}
