.files {
  width: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  input[type="file"] {
    display: none;
  }
  div.description {
    width: 400px;
  }
  label.fileInputLabel {
    width: 400px;
    height: 200px;
    border: 1px solid rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    p.name {
      color: black;
      font-size: 18px;
    }
  }
  a {
    text-decoration: none;
  }
  .description {
    font-size: 15px;
  }
  div.userFind {
    .filtered {
      position: absolute;
      background: white;
      z-index: 1;
      margin-left: 15px;
    }
  }
  .files_list {
  }
  div.sharePreview {
    display: flex;
    align-items: center;
    margin-right: 40px;
    transition: 0.2s;
    &:hover {
      transform: translateX(-5px);
      .commentsCount {
        transform: scale(1.4) translateX(5px);
      }
    }
    .commentsCount {
      display: flex;
      width: 50px;
      transition: 0.2s;
      div.commentIcon {
        width: 24px;
        height: 24px;
        background-position: center;
        background-size: auto;
        background-repeat: no-repeat;
        background-image: url(../icons/svg/comments.png);
        opacity: 0.5;
      }
      p {
        margin-left: 5px;
        font-size: 12px;
      }
    }
  }
  div.shared {
    cursor: pointer;
    margin: 5px 10px 5px 0;
    width: 300px;
    border: 1px solid rgba(0,0,0,0.1);
    display: flex;
    align-items: center;
    border-radius: 100px;
    box-shadow: 1px 1px 6px rgba(0,0,0,0.1);
    div.avatar {
      width: 32px;
      height: 32px;
      background-image: linear-gradient(rgba(100, 205, 100, 0.5), rgba(40,40,185,0.8));
      border-radius: 100px;
    }
    p.name {
      padding: 0 30px;
      font-size: 12px;
    }
  }
}
