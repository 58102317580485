
.manufacturerPage__wrapper {
  .manufacturerInfo {
    border-bottom: 1px solid #BFBFBF;
    padding-bottom: 24px;
    .row {
      display: flex;
      align-items: center;
      p {
        font-size: 18px;
      }
    }
  }
  .tabs {
    justify-content: center;
    p {
      cursor: pointer;
      padding: 10px 20px;
    }
    .currentTab {
      transition: 0.3s;
      color: #00796B;
      border-bottom: 2px solid #00796B;
    }
  }
  .table__head, .contract__row {
    display: grid;
    grid-template-columns: 20% 20% 20% 40%;
    padding: 13px;
    border-bottom: 1px dotted rgba(#263238, 0.24);
  }
}