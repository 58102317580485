@import "./variables.scss";

h1 {
  font-family: Roboto, sans-serif;
  font-style: Light;
  font-size: 9.6em;
  color: $palette_primary__contrastText;
}
h2 {
  font-family: Raleway, sans-serif;
  font-style: SemiBold;
  font-weight: 500;
  font-size: 1.5rem;
  color: $palette_primary__contrastText;
}
h3 {
  font-family: Roboto, sans-serif;
  font-style: Regular;
  font-weight: 500;
  font-size: 3rem;
  color: $palette_primary__contrastText;
}
h4 {
  font-family: Roboto, sans-serif;
  font-style: Regular;
  font-size: 2.125rem;
  color: $palette_primary__contrastText;
}
h5 {
  font-family: Roboto, sans-serif;
  font-style: SemiBold;
  font-size: 1.5rem;
  color: $palette_primary__contrastText;
}
h6 {
  font-family: Raleway, sans-serif;
  font-style: Light;
  font-size: 1.25rem;
  font-weight: 500;
  color: $palette_primary__contrastText;
}
@mixin h6 {
  font-family: Raleway, sans-serif;
  font-style: Light;
  font-size: 1.25rem;
  font-weight: 500;
  color: $palette_primary__contrastText;
}
.body1 {
  font-family: Roboto, sans-serif;
  font-style: Regular;
  font-size: 16px;
  color: $palette_primary__contrastText;
}
@mixin body1 {
  font-family: Roboto, sans-serif;
  font-style: Regular;
  font-size: 16px;
  color: $palette_primary__contrastText;
}
.body2 {
  font-family: Roboto, sans-serif;
  font-style: Medium;
  font-size: 14px;
  color: #2632389c;
  opacity: 54;
}
@mixin body3 {
  font-family: Roboto, sans-serif;
  font-style: Medium;
  font-size: 14px;
  color: $palette_primary__contrastText;
  opacity: 54;
}
.subtitle1 {
  font-family: Roboto, sans-serif;
  font-style: Regular;
  font-size: 10px;
  color: $palette_primary__contrastText;
}
@mixin typo__subtitle2 {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.1px;
  font-size: 0.875rem !important;
  color: #263238;
}
button {
  font-family: Raleway, sans-serif;
  font-style: SemiBold;
  font-size: 0.875rem;
  letter-spacing: 0.75px;
  color: $palette_primary__contrastText;
}
.button_outline {
  font-family: Raleway, sans-serif;
  font-style: semiBold;
  font-weight: 600;
  font-size: 0.875rem;
  letter-spacing: 0.75px;
  color: $palette_secondary__main;
}
@mixin button_outline {
  font-family: Raleway, sans-serif;
  font-style: semiBold;
  font-weight: 600;
  font-size: 0.875rem;
  letter-spacing: 0.75px;
  color: $palette_secondary__main;
}
caption {
  font-family: Raleway, sans-serif;
  font-style: SemiBold;
  font-size: 0.75rem;
}
.overline {
  font-family: Roboto, sans-serif;
  font-style: Medium;
  font-size: 0.625;
}

@mixin headline_text {
  font-family: Raleway, sans-serif;
  font-style: semiBold;
  font-weight: 600;
  font-size: 16px;
  color: $palette_secondary__light;
  text-transform: none;
}
@mixin button {
  font-family: Raleway, sans-serif;
  font-style: semiBold;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.75px;
  color: $palette_secondary__main;
}
@mixin pharmHeader {
  font-family: Raleway, sans-serif;
  font-style: semiBold;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.75px;
  color: $palette_primary__dark;
  text-transform: uppercase;
}
