@import "../styles/variables.scss";

.project {
  width: 100%;
  display: flex;
  .commentsWrapper {

  }
  .tasksList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 70%;

    .task {
      margin: 10px;
      padding-top: 0;
      border: 1px solid #f3f3f3;
      border-radius: 10px;
      max-width: 250px;
      cursor: pointer;
      &:hover {
        // transform: translateX(5px);
      }
      div.status {
        &.complete {
          background: $palette_green__600;
        }
        &.incomplete {
          background: $palette_secondaryAccent__dark;
        }
        height: 35px;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
      }
      div.content {
        padding: 20px;
        padding-top: 10px;
        h5 {
          font-size: 17px;
        }
        h6 {
          font-size: 15px;
          word-break: break-word;
        }
        div.subtasks {
          margin-top: 10px;
          display: flex;
          flex-wrap: wrap;
          p { color: $palette_primary__contrastText; }
          div.subtask {
            margin-right: 5px;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
  .controls {
    position: fixed;
    left: 50%;
    bottom: 50px;
  }
}
