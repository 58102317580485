$gap: 24px;
$lg: 1600px;
$md: 1024px;
$sm: 868px;
$xs: 400px;

.contract {
  display: grid;
  padding-left: 12px;
  grid-template: 96px auto / repeat(10, 1fr);
  grid-template-areas:
    "i i i i i i i i i i"
    "p p p p p p l l l l";

  column-gap: $gap;
  width: 100%;
  height: calc(100% - 138px);
  .info_container {
    grid-column: 1 / -1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: solid 0.1px #cbcecf;
    padding: 16px 0;
    .info {
      display: grid;
      grid-template: repeat(auto-fit, 32px) / 1fr;
      grid-column: 1 / 2;
    }
    .info_status {
      .item {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        :nth-child(n) {
          margin: 0 10px;
        }
        .avatar {
          width: 50px;
          height: 50px;
          img {
            margin: 0;
            width: 50px;
            height: 50px;
          }
        }
      }
    }
    .row {
      display: flex;

      .icon {
        margin: 0 $gap/2 0 $gap;
      }

      .text {
        margin: 0 $gap/2;
      }
    }
  }

  .project {
    grid-area: p;
    display: grid;
    gap: $gap;
    grid-template: 64px  repeat(auto-fit, minmax(64px, 1fr)) / repeat(2, 1fr);

    &.without_projects {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        .icon {
          font-size: 75px;
        }
      }
    }
    grid-template: 64px repeat(auto-fit, minmax(64px, 1fr)) / repeat(2, 1fr);
    .control {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      max-height: 64px;
      grid-column: 1 / -1;
    }
    .project_card {
      //grid-column: 1 / -1;
      max-height: 64px;
    }
    .text {
      text-align: center;
      width: 100px;
    }
  }
  .logs {
    border-left: solid 1px #cbcecf;
    grid-area: l;
    display: flex;
    flex-direction: column;
    position: relative;
    // display: grid;
    // grid-template: repeat(auto-fit, minmax(64px, 1fr)) /  1fr;
    .control {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      max-height: 64px;
      grid-column: 1 / -1;
    }
    .text {
      text-align: center;
      width: 100px;
    }
    .fab {
      margin-top: auto;
      margin-left: -28px;
      position: fixed;
      bottom: 30px;
      button{
        background-color: #00796b;
      }
      span {
        color: white;
      }
    }
  }
}

@media screen and (min-width: $lg) {
  .contract {
    grid-template-areas:
      "i i i i i i i i i i"
      "p p p p p p p l l l";
  }
}

@media screen and (max-width: $md) {
  .contract {
    .project {
      grid-template: 64px repeat(auto-fit, minmax(64px, 64px)) / 1fr;
    }
  }
}
