.drawerPaper {
  display: flex;
  white-space: nowrap;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: #FAFCFC;
  overflow: hidden;
  z-index: 1300;
  min-width: 230px;
  position: fixed;
  top: 64px;
  height: 100%;
  a {
    outline: none;
    text-decoration: none;
  }
  & .sidebar {
    height: 30px;
    width: 200px;
    & > div {
      padding-top: 3px;
      padding-bottom: 3px;
    }
  }
  & .adminTitle {
    text-align: center;
    margin-top: 8px;
  }
  & .sidebar__menu {
    padding-right: 15px;
  }
  & .common {
    box-shadow: 4px 0px 8px rgba(0, 0, 0, 0.12);
    border-radius: 0px 10px 0px 0px;
    padding-top: 8px;
    margin-top: 21px;
  }
  & .admin {
  }
  & .menuButton {
    width: 48px;
    height: 48px;
    margin: 5px 10px;
  }

  .active {
    background: rgba(0, 122, 106, 0.1);
    height: 30px;
    border-radius: 0px 10px 10px 0px;
    margin-right: 16px;
    svg path {
      fill: #007A6A;
    }
  }
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    .pill {
      background-image: url(../../../static/imgs/pill.png);

    }
    .projects {
      background-image: url(../../../static/imgs/projects.png);
    }
    .pharmaciesIcon {
      background-image: url(../../../static/imgs/pharmacies.png);
    }
    .drugstoreIcon {
      background-image: url(../../../static/imgs/drugstore.png);
    }
    .materials {
      background-image: url(../../../static/imgs/materials.png);
    }
    .dashboard {
      background-image: url(../../../static/imgs/dashboard.png);
    }
    .help {
      background-image: url(../../../static/imgs/help.png);
    }
    .file {
      background-image: url(../../../static/imgs/files.png);
      opacity: 0.8;
    }
  }

  &.closed {
    min-width: 60px;
    width: 60px;
    border-radius: 0px 10px 0px 0px;
    & > div {
      box-shadow: none;
    }
  }
  .ListComponent {
    height: 100%;
  }
}
