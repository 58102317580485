
.documentEditor__wrapper {
  form {
    display: flex;
    flex-direction: column;
    .row {
      display: flex;
      margin-top: 24px;
      position: relative;
      input, textarea {
        padding-right: 35px;
      }
      .documentKinds__wrapper {
        width: 100%;
        background-color: white;
        box-shadow: 0px 0px 15px rgba(0, 122, 106, 0.05);
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        font-size: 14px;
      }
      .kind {
        display: flex;
        align-items: center;
        cursor: pointer;
        height: 40px;
        padding: 0 40px;
        &.selected {
          background-color: rgba(#979797, 0.12);
          background-image: url('../../static/imgs/Check.svg');
          background-repeat: no-repeat;
          background-position: left center;
        }
      }
      button {
        text-transform: none;
        &:nth-child(1) {
          margin-left: auto;
          margin-right: 12px;
          color: #D72638;
          border: 1px solid rgba(#D72638, 0.3);
        }
      }
      .cancelIcon {
        position: absolute;
        right: 20px;
        top: 12px;
        cursor: pointer;
        width: 18px;
        height: 18px;
      }
    }
  }
}


