@import "../../styles/variables.scss";

/*from chip.jsx*/
.chip_root {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  .chip {
    display: flex;
    justify-content: space-between;
    margin: $spacing_unit;
    max-width: 200px;
  }
}
