.ticket {
  background: white;
  // right: 0;
  height: calc(100% - 152px);
  position: relative;

  &.opened {
    opacity: 1;
    transition: 0.4s;
    white-space: nowrap;
    width: 100%;
  }
  .ticket__wrapper {
    padding: 24px;
    &.closed {
      padding: 0;
      transition: padding 3s;
    }
    .message-input {
      width: 100%;
    }

    .enterComment {
      display: flex;
      flex-flow: column nowrap;
      background-color: white;
      width: calc(100vw - 150px);
      margin-top: 16px;
      position: relative;
      & input {
        outline: 0;
      }
      .message-input {
        font-size: 16px;
        padding-left: 40px;
        min-height: 48px;
        background-color: rgba(0, 121, 107, 0.1);
        border: 1px solid rgba(128, 128, 128, 0.1);
        border-radius: 10px;
      }
      .sendIcon {
        cursor: pointer;
        position: absolute;
        right: 0;
        &_progress {
          right: 12px;
          top: 12px;
        }
      }
      .withFile {
        svg {
          fill: #00796b;
        }
      }
      .fileInput {
        display: none;
      }
      label {
        cursor: pointer;
        position: absolute;
        left: 10px;
        top: 11px;
        svg {
          fill: rgba(0, 0, 0, 0.2);
          transform: rotate(45deg);
        }
      }
      .inputStyle {
        font-size: 18px;
      }
    }
    .icon {
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;
      margin: 10px;
    }
    .attach-file {
      background-image: url(../../static/imgs/attach-file.svg);
      opacity: 0.6;
    }
    .send {
      background-image: url(../../static/imgs/send.png);
    }
    .icon {
      margin-left: 5px;
      &:hover {
        cursor: pointer;
      }
    }
    .ticketInfo {
      align-items: center;
      background-color: #fcfcfc;
      border: 1px solid #f7f7f7;
      box-sizing: border-box;
      box-shadow: 0px 4px 8px rgba(176, 190, 197, 0.24);
      border-radius: 10px;
      display: flex;
      padding: 12px;
      position: sticky;
      top: 0;
      font-size: 14px;
      &__status {
        margin-left: 7px;
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
    .assessment {
      display: flex;
      margin-bottom: 24px;
      .icon {
        margin: 0;
      }
      .dislike {
        background-image: url("../../static/imgs/dislike.svg");
        margin-left: 10px;
      }
      .like {
        background-image: url("../../static/imgs/dislike.svg");
        transform: rotate(180deg);
        margin-left: 10px;
      }
    }
    // высота заданная таким образом это не хорошо
    // если так не сделать то не прижать раздел с комментариями к низу
    // в ВК атоматически высчитывается сколько снизу отступить
    .cards__wrapper {
      overflow: auto;
      height: 67vh;
      padding-right: 20px;
      .card {
        margin-top: 10px;
        border: 1px solid #f2f2f2;
        border-radius: 12px;
        box-shadow: none;
        width: 50%;
        &.cardAnswer {
          margin-left: auto;
          background-color: rgba(0, 121, 107, 0.1);
        }
        &__status {
          margin-top: 30px;
        }
      }
      .attachment {
        font-size: 13px;
        color: #555;
      }
      .personInfo {
        border: none;
        padding-bottom: 16px;
        margin: 0;
        .avatar {
          background-size: contain;
          background-repeat: repeat;
          background-position: center;
          width: 48px;
          min-width: 48px;
          height: 48px;
          margin: 0;
          margin-right: 16px;
        }
      }
      .answerButton {
        display: flex;
        align-items: center;
        cursor: pointer;
        .answer {
          margin: 0;
          margin-right: 5px;
          background-image: url("../../static/imgs/answer.svg");
        }
      }
      .attachments {
        &__list {
          display: grid;
          grid-gap: 10px;
          align-items: flex-end;
          grid-template-columns: repeat(3, 1fr);
          &_1 {
            grid-template-columns: 1fr;
          }
          &_2 {
            grid-template-columns: 1fr 1fr;
          }
          &_3 {
            grid-template-columns: 1fr 1fr;
            .attachments__item:first-child {
              grid-column: 1 / span 2;
            }
          }
          &_4 {
            .attachments__item:first-child {
              grid-column: 1 / span 3;
            }
          }
          &_5 {
            grid-template-columns: repeat(6, 1fr);
            .attachments__item {
              grid-column: span 2;
            }
            .attachments__item:first-child,
            .attachments__item:nth-child(2) {
              grid-column: span 3;
            }
          }
        }
        &__item {
          width: 100%;
          max-height: 300px;
          object-fit: contain;
          border-radius: 6px;
          cursor: pointer;
        }
      }
    }
  }
}

.modal {
  &-status {
    &__title {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      color: #808080;
      font-size: 20px;
      margin-bottom: 24px;
    }

    &__select {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
    }

    &__options {
      font-size: 16px;
      color: #263238;
    }

    &__message {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      letter-spacing: 0.1px;
      border-radius: 5px;
      outline: 0;
      height: 150px;
      box-shadow: none;
      border: 1px solid #bfbfbf;
      padding: 10px;
      margin-top: 13px;

      &::placeholder {
        color: #bfbfbf;
      }
    }

    &__group {
      margin-top: 17px;
      display: flex;
      justify-content: flex-end;
      flex-wrap: nowrap;
    }

    &__btn {
      cursor: pointer;
      padding: 10px 20px;
      border: 0;
      border-radius: 5px;

      &_confirm {
        background-color: #00796b;
        color: #ffffff;

        &:hover {
          background-color: #005347;
        }
      }

      &_cancel {
        border: 1px solid #00796b;
        color: #00796b;
        margin-right: 14px;
        background-color: #ffffff;

        &:hover {
          background-color: #d2d2d2;
        }
      }

      &_loading {
        animation-name: loading;
        animation-duration: 1s;
        animation-iteration-count: infinite;
      }
    }
  }
}

  .status {
    &-card {
      &__change {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &__icon {
        margin-right: 8px;
      }
    }
  }
