* {
  margin: 0;
  padding: 0;
}

html, body, #root {
  //height: 100%;
  font-family: Roboto, sans-serif;
  // overflow: hidden;
  & .root__wrapper {
    margin-left: 60px;
  }
  & .blurred {
    filter: blur(2px);
    transition: 0.7s;
  }


}

.MuiPaper-elevation2-24, .MuiPaper-elevation4-26 {
  box-shadow: none !important;
}
.tipFonSize {
  font-size: 12px !important;
}* {
  margin: 0;
  padding: 0;
}

html, body, #root {
  height: 100%;
  font-family: Roboto, sans-serif;
  // overflow: hidden;
}

.MuiPaper-elevation2-24, .MuiPaper-elevation4-26 {
  box-shadow: none !important;
}
.tipFonSize {
  font-size: 12px !important;
}
