/* from ProjectDashboard.jsx */
.project_dashboard {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 20px;
  height: 100%;
  max-width: calc(100% - 72px);
  border-radius: 0;
  &.opened {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0 20px;
    height: 100%;
    max-width: calc(100% - 220px);
  }
  table {
    min-width: 100;
  }
  .table-wrapper {
    overflow-x: auto;
  }
}
