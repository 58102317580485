@import "../styles/typography.scss";

@keyframes fadein {
  from { opacity: 0 }
  to { opacity: 1 }
}

.pharmacies {
  width: 100%;
  .button {
    p {
      color: #00796B;
      font-size: 14px;
      font-weight: 600;
      width: max-content;
      margin-left: 10px;
    }
  }
  div.header {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;


    div.title {
      display: inline;
    }
    
    div.buttons {
      padding-right: 40px;
    }
  }
  div.content {
    height: calc(100% - 160px);
    &.animated {
      animation-name: fadein;
      animation-duration: 0.3s;
    }
  }
}
div.tabs {
  display: flex;
  div.tab {
    @include pharmHeader;
    user-select: none;
    cursor: pointer;
    // margin: 10px 50px;
    padding: 10px 20px;
    text-transform: none;
    &.active {
      border-bottom: 2px solid #00796B;
    }
  }
}

.user {
  &__loader {
    display: inline-block;
    padding: 12px;
  }
}