@keyframes openMenu {
  0% {
    opacity: 0;
    transform: translateY(-200px);
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes closeMenu {
  0% {
    opacity: 1;
    transform: translateY(0);

  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: translateY(-200px);
  }
}
.subtask__wrapper {
  // overflow: hidden;
  transition: 0.3s;
  &.opened {
    max-height: 1000px;
  }
  &.hidden {
    max-height: 55px;
  }
}
.subtask__item {
  z-index: 999;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  min-height: 40px;
  height: max-content;
  box-shadow: 0px 0px 15px rgba(0, 122, 106, 0.05);
  border-radius: 5px;
  background: white;
  border: 1px solid #F7F7F7;
  margin-top: 15px;
  &.modyfied {
    i {
      display: block;
      background: #F2994A;
    }
  }
  &.new {
    i {
      display: block;
      background: #4a994A;
      &::before {
        // content: 'NEW';
      }
    }
  }

  i {
    display: none;
    position: absolute;
    right: 45px;
    width: 12px;
    height: 12px;
    border-radius: 100px;
  }

  .photoSubtask__wrapper {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    .cancelIcon {
      margin-left: 0;
      margin-right: 15px;
      cursor: pointer;
    }
  }
  .icon {
    margin-left: 15px;
    margin-right: 15px;
    svg {
      width: 24px;
      height: 24px;
    }
  }
  .menuClosedArrow {
    cursor: pointer;
    margin-left: 5px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid #999;
  }
  .menuOpenedArrow {
    cursor: pointer;
    margin-left: 5px;
    border-top: 5px solid #999;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
  }
  .cancelIcon {
    margin-left: auto;
    margin-right: 15px;
    cursor: pointer;
  }
}
.answer {
  display: flex;
  align-items: center;

  button {
    margin: 0 !important;
    width: 40px !important;
  }
}
.additionalMenu {
  display: flex;
  flex-direction: column;
  background: white;
  padding: 15px 15px 10px;
  border-top: 2px solid #E5E5E5;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  .course-input {
    width: 50%;
  }
  &.opened {
    animation-duration: 0.5s;
    animation-name: openMenu;
  }
  &.disabled {
    animation-fill-mode: forwards;
    animation-duration: 0.5s;
    animation-name: closeMenu;
    padding: 0;
  }
  button, select {
    border: 1px solid #E5E5E5;
    margin-top: 15px;
  }
  .drugs_input {
    width: 70%;
  }
  .answer:first-child {
    margin-top: 0;
    margin-bottom: 15px;
  }
  .select__row {
    display: flex;
    select:first-child {
      margin-right: 7.5px;
    }
    select:last-child {
      margin-left: 7.5px;
    }
  }
  .courseInfo__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    svg {
      cursor: pointer;
    }
  }
}
.counter__wrapper {
  display: flex;
  width: 50px;
  justify-content: space-around;
  align-items: center;
  margin-left: auto;
  margin-right: 30px;
  .counter__button {
    background: white;
    border: none;
    margin: 0;
    cursor: pointer;
    &:focus {
      border: none;
      box-shadow: none;
    }
  }
}
.subtask__items {
  margin: 15px 0;
  display: flex;
  svg {
    height: 30px;
    fill: rgb(229, 229, 229);
  }
  .subtask__column-1, .subtask__column-2 {
    width: 50%;
  }
  .subtask__column-1 {
    padding-right: 15px;
  }
  .subtask__column-2 {
    padding-left: 15px;
  }
}
