@keyframes closed {
  from {
    transform: translateX(-500px);
    opacity: 1;
  }
  to {
    transform: translateX(0px);
    opacity: 0;
  }
}
@keyframes loading {
  from { opacity: 0.6; }
  to { opacity: 1; }
}

.content__wrapper {
  display: flex;
  .modal-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    .modal {
      background: white;
      padding: 20px 20px;
      position: relative;
      border-radius: 12px;
      p {
        font-size: 20px;
        margin-bottom: 10px;
      }
      input, textarea {
        border-radius: 5px;
        outline: 0;
        box-shadow: none;
        border: 1px solid #bfbfbf;
        padding: 10px;
        max-width: 400px;
        min-width: 400px;
      }
      textarea {
        height: 150px;
        margin-top: 7px;
        margin-bottom: 50px;
      }
      button {
        cursor: pointer;
        position: absolute;
        right: 20px;
        bottom: 20px;
        display: inline-block;
        align: right;
        // margin-top: 17px;
        padding: 10px 20px;
        border: 0;
        outline: 0;
        background-color: #DFEEEC;
        color: #999999;
        border-radius: 5px;
        &.loading {
          animation-name: loading;
          animation-duration: 1s;
          animation-iteration-count: infinite;
        }
      }
      .close {
        background-image: url(../static/imgs/close.png);
        background-position: center;
        background-repeat: no-repeat;
        background-color: white;
        position: absolute;
        cursor: pointer;
        right: 20px;
        top: 25px;
        width: 20px;
        height: 20px;
      }
    }
  }
  .table {
    width: 100%;
  }
  .item:hover {
    background:rgba(224, 224, 224, 1);
    cursor: pointer;
  }
}
