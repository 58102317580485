@import "../../styles/variables.scss";

.subtask {
  padding-bottom: 20px;
  p.header {
    font-size: 20px;
    border-bottom: 1px solid rgb(241,241,241);
    padding-bottom: 3px;
  }
  &.map {
    height: 400px;
  }
  div.container {
    margin-top: 5px;
    display: flex;
    div.icon {
      width: 56px !important;
      height: 50px !important;
      background: $palette_primary__dark;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
      margin-top: 10px;
      margin-right: 10px;
    }
    div.content {
      width: 100%;
      * {
        width: 100%;
      }
    }
  }
  .poll-answers {
    .answer {
      display: flex;
      margin: 5px 0;
      background: rgb(241,241,241);
      padding: 5px 10px;
      border-radius: 20px;
      .delete {

        cursor: pointer;
        width: 16px;
        height: 16px;
        background-image: url(../../static/imgs/delete.svg);
        opacity: 0.3;
      }
    }
  }
}
.subtasks__wrapper {
  .subtasks-menu {
    display: none;
    background-color: black;
    position: absolute;
    right: 0;
    width: 200px;
    z-index: 999;
    padding: 10px 20px;
    &.visible {
      display: block;
    }
    * {
      color: white;
      margin: 5px 0;
      cursor: pointer;
    }
  }
}
