.logs {
  .log {
    gap: 70px;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-areas: "a a i i i i i ac";

    .userAvatar_container {
      grid-area: a;
      display: flex;
      flex-direction: row;
      margin: 0 8px;
      .info_persona{
        margin: 0 8px;
        display: flex;
        flex-direction: column;
      }
    }

    .info {
      grid-area: i;
    }

    .actions {
      grid-area: ac;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      //justify-content: flex-end;
      .actions_status{
        padding: 0 8px;
      }
      .actions_cancel{
        span{
          color: #00796b;
          text-transform: none;
        }
      }
    }

  }
}
