@import "../../styles/typography";
@import "../../styles/variables";

.drugstore {
  &__toolbar {
    background-color: transparent;
    display: flex;
    justify-content: center;
  }
}

.drugstore__wrapper {
  width: 100%;
  .header {
    display: flex;
    height: 200px;
    border-bottom: solid 0.1px #cbcecf;
    .drugstoreInfo__wrapper {
      width: 60%;
      display: flex;
      flex-direction: column;
      padding: 16px;
      .drugstoreInfo {
        padding-right: 10px;
        width: 70%;
        .drugstore__title {
          display: flex;
          align-items: center;
          margin-bottom: 8px;
          .title__icon {
            background-image: no-repeat center url(/src/static/imgs/diplomat.png);
            width: 40px;
            height: 40px;
            background-color: #979797;
            margin-right: 8px;
          }
          .title__taxID {
            color: #979797;
            font-size: 12px;
            font-weight: 600;
            margin-left: 8px;
          }
        }
        .drugstore__description {
          display: flex;
          align-items: center;
          .description__icon {
            background-image: no-repeat center url(/src/static/imgs/files.png);
            min-width: 40px;
            min-height: 40px;
            background-color: #979797;
          }
          p {
            color: #979797;
            font-size: 12px;
            font-weight: 600;
            margin-left: 8px;
          }
        }
      }
      .drugstoreStatus {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .workCondition {
          color: $palette_secondary__main;
          display: flex;
          align-items: center;
          .whiteRightArrow__icon {
            width: 24px;
            height: 24px;
            background-color: $palette_secondary__main;
            border-radius: 50%;
            margin-right: 12px;
            padding: 6px;
            .arrow {
              width: 0;
              height: 0;
              border-left: 6px solid white;
              border-top: 6px solid transparent;
              border-bottom: 6px solid transparent;
              margin-left: 40%;
            }
          }
          .blackDownArrow__icon {
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-top: 6px solid black;
            border-right: 6px solid transparent;
            margin-left: 12px;
            cursor: pointer;
          }
        }
        .seniorStaff {
          font-size: 12px;
          color: $palette_controlAction__disable;
          margin-top: auto;
        }
      }
      .drugstore__contacts {
        margin-top: auto;
        text-align: center;
        a {
          color: $palette_secondary__main;
          text-decoration: none;
          margin: 8px;
        }
      }
    }
    .drugstoreMap {
      height: 210px;
      width: 40%;
      margin-top: -10px;
      -webkit-mask-image: -webkit-gradient(linear, right center,
           left center, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
      .ymaps-2-1-63-gotoymaps-container, .ymaps-2-1-74-copyright__content {display: none}
    }
  }
  .moreInfo {
    position: relative;
    .moreInfo__button {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      position: absolute;
      bottom: -12px;
      left: 50%;
      background: white;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      cursor: pointer;
      z-index: 1;

      .downArrow {
        width: 10px;
        height: 10px;
        border-bottom: 2px solid #979797;
        border-right: 2px solid #979797;
        transform: rotate(45deg);
        margin: auto;
        margin-top: 20%;
      }
    }

  }
  .tabs {
    display: flex;
    div.tab {
      @include pharmHeader;
      user-select: none;
      cursor: pointer;
      margin: 10px 50px;
      padding: 10px 20px;
      &.active {
        border-bottom: 2px solid #00796B;
      }
    }
  }
}
.verticalCards__wrapper {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  justify-content: center;
  margin: 30px;

  .course {
    width: 100%;
    align-items: center;
    height: auto;
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2),
    0px 1px 1px 0px rgba(0,0,0,0.14),
    0px 2px 1px -1px rgba(0,0,0,0.12);
    border-radius: 4px;
    overflow: hidden;

    .textBlock {
      padding: 0 15px 15px;
      .textRow {
        display: flex;
      }
    }
    .buttonRow {
      display: flex;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      button {
        font-size: 12px;
        width: 50%;
        text-transform: none;
      }
      button + button {
        border-left: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
    .big-button {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      width: 100%;
    }
    .topRow {
      display: flex;
      flex-direction: row;
    }
    .rewardPoints {
      display: flex;
      padding-right: 10px;
      padding-left: 10px;
      position: relative;
      overflow-x: hidden;
      width: 100%;
      height: auto;
      background-color: rgba(255, 255, 255, 0.8);
      top: -180px;
      left: 44%;
      font-size: 16px;
      white-space: nowrap;
      .symbol {
        padding-right: 10px;
        font-weight: 1000;
      }
    }
    img {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }
  }
}

.course {
  &-list {
    &__header {
      display: grid;
      grid-auto-flow: column;
      //grid-template-areas: '. search filter';
      grid-template-columns: 1fr auto;
      justify-content: space-between;
      grid-gap: 30px;
      margin-top: 30px;
      align-items: center;
    }
    &__search {
      max-width: 500px;
      width: 100%;
      //grid-area: search;
    }
    &__filter {
      width: 100%;
      //grid-area: filter;
    }
  }
}
