//Manufacturers
.manufacturers {
  background-color: #FFF;
}
.manufacturers__button {
  align-items: center;
  background-color: transparent;
  border: none;
  color: #00796b;
  cursor: pointer;
  min-width: fit-content;
  padding: 10px 15px;
  width: 15%;
  &:hover {
    transition: 0.4s;
    background-color: rgba(#00796b, 0.1);
  }
}
.button__content {
  align-items: center;
  display: flex;
  font-size: 16px;
  justify-content: space-around;
}

//Create-manufacturer
.create-manufacturer {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  padding-top: 20px 0;
}
.create-manufacturer__form {
  padding-top: 5px;
}
.hint__color-selection {
  color: #D50000;
}
.form__row {
  align-items: center;
  display: flex;
  padding: 9px 0;
  width: 50%;
  svg {
    width: 40px;
  }
}
.input-group {
  position: relative;
  width: 100%;
}
.input-label {
  background-color: #ffffff;
  color: #808080;
  left: 20px;
  padding: 0 3px;
  position: absolute;
  top: 30%;
  transition: 0.1s;
}
.input-label__top {
  color: #979797;
  font-size: 12px;
  top: -15%;
  transition: 0.1s;
}
.input-area, .autocomplete-input > input {
  border-radius: 5px;
  border: 1px solid #cbcecf;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  outline: none;
  padding: 12px 15px;
  width: 100%;
  &:focus {
    border: 1px solid #00796b;
    box-shadow: 0 0 3px #00796b;
  }
}
.form__button {
  background-color: transparent;
  border-radius: 5px;
  font-size: 16px;
  padding: 10px 15px;
}
.form__cancel-button {
  margin-left: auto;
  margin-right: 15px;
  border: 2px solid #D50000;
  color: #D50000;
}
.form__save-button {
  background-color: #24796B;
  border: 2px solid #24796B;
  color: #ffffff;
}